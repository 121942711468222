@layer components {

  .section {
    @apply m-4;
  }

  /* forms */
  .help {
    @apply text-xs text-gray-700 dark:text-gray-300;
  }

  .app {
    @apply m-4;
  }

  .app-card {
    @apply lg:shadow-md p-4 mb-2 mt-2 lg:mt-0 lg:p-8;
  }

}
