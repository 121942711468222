@import "tailwindcss/base";

/*
   add custom components using imports, as described here:
   https://tailwindcss.com/docs/using-with-preprocessors#build-time-imports
*/
@import "tailwindcss/components";
@import "./app/tailwind/app-components.css";
@import "./app/tailwind/chat-components.css";
@import "./pegasus/tailwind.css";
@import "./optimae/tailwind.css";

@import "tailwindcss/utilities";
@import "./shadcn-base.css";

/* set pegasus css variables to DaisyUI default colors: https://daisyui.com/docs/colors/ */

:root {
  --primary: oklch(var(--p));
  --danger: oklch(var(--er));
  --success: oklch(var(--su));
}
