@layer base {
  :root {
    /* Cores base */
    --optimae-navy: 0, 51, 102;      /* #003366 */
    --optimae-slate: 47, 79, 79;     /* #2F4F4F */
    --optimae-accent: 242, 153, 74;  /* #F2994A */
    
    /* Variáveis de design */
    --font-primary: 'Noto Sans', 'Segoe UI', system-ui, sans-serif;
    --font-secondary: Georgia, Merriweather, serif;
    
    --optimae-primary: #0284c7;
    --optimae-secondary: #64748b;
  }
}

@layer utilities {
  /* Cores de texto */
  .opt-text-navy {
    @apply text-optimae-navy;
  }
  .opt-text-navy-light {
    @apply text-optimae-navy-light;
  }
  .opt-text-navy-faint {
    @apply text-optimae-navy-faint;
  }

  /* Cores de fundo */
  .opt-bg-navy {
    @apply bg-optimae-navy;
  }
  .opt-bg-navy-light {
    @apply bg-optimae-navy-light;
  }
  .opt-bg-navy-faint {
    @apply bg-optimae-navy-faint;
  }

  /* Bordas */
  .opt-border-navy {
    @apply border-optimae-navy;
  }
  .opt-border-navy-faint {
    @apply border-optimae-navy-faint;
  }

  /* Hover states */
  .opt-menu-hover {
    @apply hover:bg-optimae-navy-faint transition-colors duration-200;
  }

  .hover\:bg-optimae-navy-faint:hover {
    @apply bg-optimae-navy-faint;
  }
  .hover\:bg-optimae-navy-light:hover {
    @apply bg-optimae-navy-light;
  }
  .hover\:bg-optimae-navy:hover {
    @apply bg-optimae-navy;
  }
}

@layer components {
  /* Tipografia */
  .opt-heading-1 {
    @apply font-heading text-2xl font-bold text-optimae-navy mb-5;
  }

  .opt-heading-2 {
    @apply font-heading text-xl font-semibold text-optimae-navy mb-4;
  }

  .opt-heading-3 {
    @apply font-heading text-lg font-medium text-optimae-slate mb-3;
  }

  .opt-body {
    @apply font-sans text-sm text-contrast-high;
  }

  .opt-body-secondary {
    @apply text-base text-[#336699];
  }

  /* Cards */
  .opt-card {
    @apply bg-white rounded-lg shadow-lg p-4 border border-[#CCD6E5];
  }

  /* Botões */
  .opt-button-primary {
    @apply bg-[#003366] text-white hover:bg-[#334C80] 
           px-4 py-2 rounded-md transition-colors duration-200;
  }

  .opt-button-secondary {
    @apply bg-[#2F4F4F] text-white hover:bg-[#597272] 
           px-4 py-2 rounded-md transition-colors duration-200;
  }

  .opt-button-accent {
    @apply bg-[#F2994A] text-white hover:bg-[#F4AB6C] 
           px-4 py-2 rounded-md transition-colors duration-200;
  }

  /* Tabelas */
  .opt-table {
    @apply w-full text-sm border-collapse;
  }

  .opt-table-header {
    @apply bg-[#003366] text-white;
  }

  .opt-table-row {
    @apply border-b border-[#CCD6E5] transition-colors;
  }

  .opt-table-row-hover {
    @apply hover:bg-[#CCD6E5]/50;
  }

  .opt-table-cell {
    @apply py-2 px-4 align-middle whitespace-nowrap;
  }

  .opt-table-cell-numeric {
    @apply text-right;
  }

  .opt-table-group {
    @apply bg-[#CCD6E5] font-medium;
  }

  .opt-table-total {
    @apply bg-[#334C80] text-white font-medium;
  }

  /* Filtros */
  .opt-filter-container {
    @apply bg-white rounded-lg shadow-sm p-2 mb-2;
  }

  .opt-filter-header {
    @apply flex justify-between items-center mb-1;
  }

  /* Métricas */
  .opt-metric-box {
    @apply bg-white rounded-lg shadow p-2 flex flex-col;
  }

  .opt-metric-value {
    @apply text-xl font-bold text-[#003366];
  }

  /* Layout compacto */
  .opt-compact-text {
    @apply text-xs leading-tight;
  }

  .opt-compact-heading {
    @apply text-sm font-medium mb-1;
  }

  .opt-compact-card {
    @apply p-2 space-y-2;
  }

  .opt-compact-select {
    @apply text-xs py-1;
  }

  .opt-compact-checkbox {
    @apply w-3 h-3;
  }

  .opt-compact-label {
    @apply text-xs leading-none;
  }

  .opt-compact-scroll {
    @apply max-h-[200px] overflow-y-auto;
  }

  .opt-compact-grid {
    @apply grid grid-cols-2 gap-1;
  }

  /* Layout específico para filtros */
  .opt-filter-layout {
    @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2;
  }

  /* Botão de colapso */
  .opt-collapse-button {
    @apply text-xs p-1 rounded hover:bg-[#CCD6E5];
  }

  /* Container colapsável */
  .opt-collapsible {
    @apply transition-all duration-200 ease-in-out;
  }

  .opt-collapsible[data-state="closed"] {
    @apply h-0 overflow-hidden;
  }

  /* Menu específico */
  .menu-title {
    @apply font-heading text-xs font-semibold text-gray-500 px-4 py-2;
  }

  .menu-list a {
    @apply font-sans text-sm py-2 px-4 hover:bg-[#CCD6E5] transition-colors duration-200;
  }

  .menu-list a.active {
    @apply bg-[#CCD6E5] text-[#003366] font-medium;
  }
}